import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>
                        Arriba la primera edició de l'intensiu de 8 hores del temari i coneixements
                        de Terrassa 2025
                    </strong>
                </p>

                <p>
                    Un curs molt complet on tots els alumnes assistents rebran un{" "}
                    <strong>dossier esquematitzat i actualitzat</strong>.
                </p>

                <p>
                    Al curs ens centrarem en els <strong>conceptes clau</strong> de les bases de la
                    convocatòria i analitzarem punt per punt els temes més susceptibles de sortir a
                    l'examen.
                </p>

                <p>
                    El curs és impartit per <strong>dos docents de referència</strong>, i estem
                    convençuts que serà un curs per anar a per nota el dia de la prova.
                </p>

                <p>
                    A més, en el moment que us matriculeu al curs, tindreu accés a un{" "}
                    <strong>campus virtual amb actualitat i cultura general</strong>.
                </p>

                <p>
                    <strong>El campus estarà disponible fins al 30 d'abril.</strong>
                </p>

                <ul>
                    <li>
                        <strong>Preu total del curs:</strong> 99,99 euros
                    </li>
                    <li>
                        <strong>Lloc:</strong> C/ Martí Codolar, núm. 18, Hospitalet de Llobregat
                    </li>
                    <li>
                        Horari: Diumenge 27 d'abril de 2025, de 9 a 14 hores i de 15 a 18 hores.
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="💥 Prepara’t per destacar a l’examen amb l’intensiu més complet i actualitzat de Terrassa 2025!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
